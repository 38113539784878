import React from 'react'
import Home from '../pages/Home';
import Bio from '../pages/Bio';
import Web from '../pages/Web';
import Android from '../pages/Android';
import Games from '../pages/Games';
import Contact from '../pages/Contact';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

function AnimatedRoutes() {
    const location  = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact path='/' element={<Home/>}> </Route>
                <Route path='/bio' element={<Bio/>}> </Route>
                <Route path='/web' element={<Web/>}> </Route>
                <Route path='/android' element={<Android/>}> </Route>
                <Route path='/games' element={<Games/>}> </Route>
                <Route path='/contact' element={<Contact/>}> </Route>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes