import React, { Component } from 'react';
import "./Contact.css";
import { Link } from "react-router-dom"
import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function Contact(){

  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  )

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);

    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);
  
    return (
    <div> 
     
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`container-fluid my-body ${isVisible ? 'visible' : ''}`}>

        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
            <animated.div style={propsSlideRightOp}>
              <div className='container-fluid top-bar'>
          
                <div className='row align-items-center '>
                  <div className='h1 fw-light text-decoration'>Contact me</div>
                </div>
              </div>
            </animated.div>
        </motion.div>

        <br/>

        <motion.div key="contact-body" initial={{opacity:0 , transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.7, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
          <div className='details-div'>
            <div className='h3 fw text-decoration'>Konstantinos Antzoulidis</div>
          
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLocationDot} size="lg" /> Athens, Greece</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLocationDot} size="lg" /> Tunbridge Wells, United Kingdom</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faEnvelope} size="lg" /> kostas.antzou@gmail.com</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLinkedin} size="lg" /> linkedin.com/in/k.antzoulidis</p>
          </div>
        </motion.div>

        <motion.div key="body" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.8 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
  
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 mt-5">
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Your Name</label>
                  <input type="text" className="form-control" id="name" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Your Email</label>
                  <input type="email" className="form-control" id="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea className="form-control" id="message" rows="4" required></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>



        </motion.div>

      </motion.div>
      


    </div>

    );
    
  }
export default Contact;