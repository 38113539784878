import React, { Component } from 'react';
import "./Android.css";
import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect, useRef} from 'react';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import android_anim from "../images/android_animation.gif";

function Android(){
  const imgRef = useRef(null);

  const restartGif = () => {
    if (imgRef.current) {
      // Force the browser to reload the image by changing its source
      imgRef.current.src = android_anim;
    }
  };

  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  )

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Call restartGif when the component mounts
    restartGif();
  }, []);

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);
    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);
  
    return (
    <div> 
     
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`container-fluid my-body ${isVisible ? 'visible' : ''}`}>

        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
            <animated.div style={propsSlideRightOp}>
              <div className='container-fluid top-bar'>
          
                <div className='row align-items-center '>
                  <div className='h1 fw-light text-decoration'>Android Development &nbsp;<FontAwesomeIcon icon={faAndroid} size="lg" style={{color: "#000000",}} /></div>
                </div>
              </div>
            </animated.div>
        </motion.div>

        <br/>

        <motion.div key="body" initial={{opacity:0, y:'100%', transition: { duration: 0.1}}} animate={{opacity:100, y:'0%', transition: { duration: 0.7, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, y:'100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
          <img ref={imgRef} src={android_anim} className="android_animation" style={{ width: '90vw ', height: 'auto' }}></img>
        </motion.div>

      </motion.div>
      


    </div>

    );
    
  }
export default Android;