import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const OverlayImage = ({ currentTab }) => {
  const controls = useAnimation();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    // Trigger a slow continuous rotation
    controls.start({
      rotate: 0,
      transition: {
        duration: 60,          // Slow rotation speed, taking 60 seconds for a full 360-degree turn
        ease: "linear",
        repeat: Infinity       // Continuous rotation
      }
    });
  }, [controls]);

  useEffect(() => {
    // Accelerate rotation briefly when tab changes
    const accelerateRotation = async () => {
      await controls.start({
        rotate: rotation - 2,   // Rotate an additional 360 degrees
        transition: {
          duration: 0.5,            // Acceleration duration of 1 second
          ease: "easeOut"
        }
      });
      
      // Return to slow rotation after acceleration
      controls.start({
        rotate: rotation + 220,   // Another 360-degree turn to continue smooth rotation
        transition: {
          duration: 60,           // Slow rotation speed again (60 seconds for each 360 degrees)
          ease: "easeIn",
          repeat: Infinity        // Continuous rotation
        }
      });
    };

    setRotation(prevRotation => prevRotation + 360); // Increment rotation state
    accelerateRotation();
  }, [currentTab]);

  return (
    <motion.div
      className="overlay-image"
      animate={controls}
    />
  );
};

export default OverlayImage;
