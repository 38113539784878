// StarfieldBackground.js
import React, { useEffect, useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Stars } from '@react-three/drei';

const RotatingStars = ({ rotationSpeed }) => {
  const groupRef = useRef();

  // Rotate the starfield slowly over time
  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.x += rotationSpeed;
      groupRef.current.rotation.y += rotationSpeed;
    }
  });

  return (
    <group ref={groupRef}>  
      <Stars radius={100} depth={100} count={800} factor={5} saturation={10} fade />
    </group>
  );
};

const StarfieldBackground = ({ currentTab }) => {
  const [rotationSpeed, setRotationSpeed] = useState(0.0005); // Initial very slow rotation speed

  // Smooth transition function for star rotation speed
  const smoothAdjustSpeed = (initialSpeed, targetSpeed, duration) => {
    const startSpeed = initialSpeed;
    const speedChange = targetSpeed - startSpeed;
    const startTime = performance.now();

    const easeOutExpo = (x) => 1 - Math.pow(2, -10 * x);

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const easedProgress = easeOutExpo(progress);
      const interpolatedSpeed = startSpeed + easedProgress * speedChange;

      setRotationSpeed(interpolatedSpeed);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  // Trigger smooth speed transition on currentTab change
  useEffect(() => {
    smoothAdjustSpeed(0.02, 0.0005, 3000); // Smooth increase and decrease
  }, [currentTab]);

  return (
    <Canvas style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", pointerEvents: "none" }}>
      <ambientLight intensity={0.8} />
      <RotatingStars rotationSpeed={rotationSpeed} />
    </Canvas>
  );
};

export default StarfieldBackground;
