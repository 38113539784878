import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import AnimatedRoutes from './components/AnimatedRoutes';
import Footer from './components/Footer';
import './App.css'; // Import your app-wide styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import main_anim from "./images/main_anim.gif";
import OverlayImage from './components/OverlayImage';
import StarfieldBackground from './components/StarfieldBackground';
import CloudBackground from './components/CloudBackground';
import { motion } from 'framer-motion';

function App() {
  return (
    <div>
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

function AppContent() {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState('/');
    const handleTabChange = (newTab) => {
        setCurrentTab(newTab);
    };
  const [backgroundColor, setBackgroundColor] = useState('white');
  const [textColor, setTextColor] = useState('white');

  useEffect(() => {
    // Handle styles or side effects based on the current location
    // console.log('Location changed:', location.pathname);

    // Example: Change background color based on the route
    if (location.pathname === '/bio') {
      setBackgroundColor('lightblue');
      setTextColor('black');
    } 
    else if (location.pathname === '/web') {
      setBackgroundColor('black');
      setTextColor('white');
    } 
    else if (location.pathname === '/android') {
      setBackgroundColor('lightgreen');
      setTextColor('black');
    } 
    else if (location.pathname === '/games') {
      setBackgroundColor('#00050D');
      setTextColor('#FFF981');
    } 
    else if (location.pathname === '/contact') {
      setBackgroundColor('#f2f28d');
      setTextColor('black');
    } 
    else {
      setBackgroundColor('white'); // Default background color
      setTextColor('black');
    }

    // You can add more conditions for other routes as needed
  }, [location]);

  document.addEventListener('scroll', function() {
    const ideaIcon = document.querySelector('.idea-icon');

    if (!ideaIcon) return;
    const scrollPosition = window.scrollY; // Get the current scroll position
    // const ideaIcon = document.querySelector('.idea-icon');

    // Adjust the top position based on the scroll position
    // The divisor (e.g., 2) controls the parallax effect strength
    ideaIcon.style.top = `${scrollPosition * 0.5}px`; // Adjust this factor as needed
  });

  return (
    <div className="App" style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s ease' }}>
      <Navbar onTabChange={handleTabChange} />
      <StarfieldBackground currentTab={currentTab} />
      { (location.pathname === '/web') &&
          <motion.div initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 1, delay:0.6 , ease: "easeInOut"}}} exit={{opacity:0, scale : 1, transition: { duration: 1, ease: "easeInOut" }}}>
             <CloudBackground/>
          </motion.div>
      }
      
     
      { (location.pathname !== '/web' && location.pathname !== '/games') &&
          <motion.div initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, scale : 0, transition: { duration: 0.5, ease: "easeInOut" }}}>
            <OverlayImage currentTab={currentTab} className='idea-icon'/>
          </motion.div>
      }
      { (location.pathname !== '/web' && location.pathname !== '/games') &&
          <motion.div initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, scale : 0, transition: { duration: 0.5, ease: "easeInOut" }}}>
            <OverlayImage currentTab={currentTab} className='idea-icon'/>
          </motion.div>
      }
      
      <AnimatedRoutes />
      <Footer />
    </div>
  );
}

export default App;
