import React, { useState, useEffect } from 'react';
import './SuccessMessage.css'; // Import CSS file for styling

const SuccessMessage = ({ score, onNextLevel, touches, points }) => {
  const [displayScore, setDisplayScore] = useState(0);

  useEffect(() => {
    let timer;
    if (score > 0) {
      // Increment the displayScore value gradually to the actual score value
      timer = setInterval(() => {
        setDisplayScore((prevScore) => {
          const increment = Math.ceil((score - prevScore) / 5); // Divide the difference by 10 for smooth animation
          const nextValue = prevScore + increment;
          return nextValue >= score ? score : nextValue;
        });
      }, 100);
    }

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [score]);

  return (
    <div className="success-message-container container">
      <div className="success-message">
        <div className='h4 fw text-decoration'>Level cleared!</div>
        <div className='h4 fw-light text-decoration'>Points score &nbsp;&nbsp;:&nbsp;&nbsp;{points}</div>
        <div className='h4 fw-light text-decoration'>Touches left &nbsp;&nbsp;:&nbsp;&nbsp;{touches?<span>{touches} * 100</span>:<span>&nbsp;&nbsp;0</span>}</div>
        <hr></hr>
        <div className='h2 fw-light text-decoration'>Score&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{displayScore}</div>
        <div className='btn btn-sm btn-success fw-light' onClick={onNextLevel}>Next Level</div>
      </div>
    </div>
  );
};

export default SuccessMessage;