// Footer.js

import React, { Component } from 'react';
// import bg from "../images/footer.webp";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
        <div>
            <div className="row align-items-center Footer foot">
                <div className='col fw-light foot-text'>kajoo.gr</div>
                <div className='col fw-light foot-text'>Copyright© 2024-2025 </div>
            </div>
            <div className="row align-items-center">
                {/* <img src={bg} class="img-fluid" alt="..."/> */}
            </div>
        </div>
    );
  }
}

export default Footer;