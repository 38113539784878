// MineSweeperGame.js
import React, { useState, useEffect } from 'react';
import "./MineSweeperGame.css";
import { motion } from 'framer-motion';
import { Switch } from '@mui/material';

const generateBoard = (rows, cols, mines) => {
  // Initialize the board with unique cell objects
  const board = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => ({
      isMine: false,
      isRevealed: false,
      isFlagged: false,
      adjacentMines: 0,
    }))
  );

  // Place mines randomly
  let mineCount = 0;
  while (mineCount < mines) {
    const row = Math.floor(Math.random() * rows);
    const col = Math.floor(Math.random() * cols);
    if (!board[row][col].isMine) {
      board[row][col].isMine = true;
      mineCount++;
    }
  }

  // Count adjacent mines for each cell
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < cols; c++) {
      if (!board[r][c].isMine) {
        board[r][c].adjacentMines = countAdjacentMines(board, r, c);
      }
    }
  }

  return board;
};

const countAdjacentMines = (board, row, col) => {
  const directions = [
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, -1],
    [0, 1],
    [1, -1],
    [1, 0],
    [1, 1],
  ];
  return directions.reduce((count, [dx, dy]) => {
    const newRow = row + dx;
    const newCol = col + dy;
    if (
      newRow >= 0 &&
      newRow < board.length &&
      newCol >= 0 &&
      newCol < board[0].length &&
      board[newRow][newCol].isMine
    ) {
      count++;
    }
    return count;
  }, 0);
};

const revealEmptyCells = (board, row, col) => {
  const stack = [[row, col]];
  const visited = new Set();

  while (stack.length) {
    const [r, c] = stack.pop();
    const key = `${r},${c}`;

    if (visited.has(key) || board[r][c].isMine || board[r][c].isRevealed)
      continue;

    board[r][c].isRevealed = true;
    visited.add(key);

    if (board[r][c].adjacentMines === 0) {
      const directions = [
        [-1, -1],
        [-1, 0],
        [-1, 1],
        [0, -1],
        [0, 1],
        [1, -1],
        [1, 0],
        [1, 1],
      ];
      directions.forEach(([dx, dy]) => {
        const newRow = r + dx;
        const newCol = c + dy;
        if (
          newRow >= 0 &&
          newRow < board.length &&
          newCol >= 0 &&
          newCol < board[0].length &&
          !board[newRow][newCol].isRevealed
        ) {
          stack.push([newRow, newCol]);
        }
      });
    }
  }
};

const MineSweeperGame = ({ rows = 10, cols = 10, mines = 10 , resetComponent}) => {
  const [level,setLevel] = useState(1);
  const [levelRows, setLevelRows] = useState(5);
  const [levelCols, setLevelCols] = useState(5);
  const [levelMines, setLevelMines] = useState(3);
  
  const [board, setBoard] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [win, setWin] = useState(false);


  useEffect(() => {
    switch (level) {
        case 1:
            setLevelRows(5);
            setLevelCols(5);
            setLevelMines(3);
            break;
        case 2:
            setLevelRows(8);
            setLevelCols(8);
            setLevelMines(10);
            break;
        case 3:
            setLevelRows(10);
            setLevelCols(10);
            setLevelMines(20);
            break;
        case 4:
            setLevelRows(12);
            setLevelCols(12);
            setLevelMines(30);
            break;
        case 5:
            setLevelRows(15);
            setLevelCols(15);
            setLevelMines(40);
            break;  
        default:
            setLevel(1);
            setLevelRows(5);
            setLevelCols(5);
            setLevelMines(3);
          break;
      }
    setBoard(generateBoard(levelRows, levelCols, levelMines));
  }, [level,levelCols,levelRows,levelMines]);

  const resetGame = (levelInput) => {
    setLevel(levelInput);
    switch (level) {
        case 1:
            setLevelRows(5);
            setLevelCols(5);
            setLevelMines(3);
            break;
        case 2:
            setLevelRows(8);
            setLevelCols(8);
            setLevelMines(10);
            break;
        case 3:
            setLevelRows(10);
            setLevelCols(10);
            setLevelMines(20);
            break;
        case 4:
            setLevelRows(12);
            setLevelCols(12);
            setLevelMines(30);
            break;
        case 5:
            setLevelRows(15);
            setLevelCols(15);
            setLevelMines(40);
            break;  
        default:
            setLevel(1);
            setLevelRows(5);
            setLevelCols(5);
            setLevelMines(3);
          break;
      }
    setBoard(generateBoard(levelRows, levelCols, levelMines));
    setGameOver(false);
    setWin(false);
  };

  const handleClick = (row, col) => {
    if (gameOver || board[row][col].isRevealed || board[row][col].isFlagged)
      return;

    const newBoard = board.map((row) => row.map((cell) => ({ ...cell })));

    if (newBoard[row][col].isMine) {
      setGameOver(true);
      newBoard[row][col].isRevealed = true;
    } else {
      revealEmptyCells(newBoard, row, col);
      if (checkWin(newBoard)) setWin(true);
    }

    setBoard(newBoard);
  };

  const handleRightClick = (e, row, col) => {
    e.preventDefault();
    if (gameOver || board[row][col].isRevealed) return;

    const newBoard = board.map((row) => row.map((cell) => ({ ...cell })));
    newBoard[row][col].isFlagged = !newBoard[row][col].isFlagged;
    setBoard(newBoard);
  };

  const checkWin = (board) => {

    for (let r = 0; r < levelRows; r++) {
      for (let c = 0; c < levelCols; c++) {
        if (!board[r][c].isMine && !board[r][c].isRevealed) return false;
      }
    }
    return true;
  };

  const getColorForAdjacentMines = (adjacentMines) => {
    switch (adjacentMines) {
      case 1:
        return 'green';
      case 2:
        return 'blue';
      case 3:
        return 'red';
      case 4:
        return 'purple';
      case 5:
        return 'maroon';
      case 6:
        return 'turquoise';
      case 7:
        return 'black';
      case 8:
        return 'gray';
      default:
        return 'black';
    }
  };

  function getRandomFloat(min, max, decimals) {
    const str = (Math.random() * (max - min) + min).toFixed(
      decimals,
    );
  
    return parseFloat(str);
  }

  return (
    <div>
    <div style={{ display: 'inline-block', textAlign: 'center', justifyItems:'center'}}>
      <motion.div initial={{ "-webkit-filter": "drop-shadow(0px 0px 0px #ffffffad)",opacity:0 , transition: { duration: 0.1}}} animate={{"-webkit-filter": "drop-shadow(3px 0px 3px #ffffffad)",opacity:100, transition: { duration: 0.5, delay:0 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}><h2>MineSweeper - Level {level}</h2><br/></motion.div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${level===1?5:(level===2?8:(level===3?10:(level===4?12:(level===5&&15))))}, 50px)`,
        }}
      >
        {board.map((row, r) =>
          row.map((cell, c) => {
            var i = getRandomFloat(0.1,0.5,1);
            var j = getRandomFloat(0.1,1,1);
            var k = getRandomFloat(-1,1,0);
            return(
            <motion.button 
            
            initial={{opacity:0, rotate:-150*i*(j>0.5)?1:-1,rotateZ:'90deg',scale:0, y:'30vh', x:`${2000*i*k}px`, transition: { duration: 0.1}}} 
            animate={{opacity:100,rotate:0,rotateZ:'0deg',scale:1, y:'0%', x:'0%', transition: { duration: 0.8, delay:i+0.5 , ease: "backOut"}}} 
            exit={{opacity:0,margin:"2rem", y:`200vh`, scale:0, rotate:50*i*j>0.5?1:-1,rotateZ:"20deg", transition: { duration: 0.5, delay:i, ease: "easeInOut" }}}

            key={`${r}-${c}`}
            onClick={() => handleClick(r, c)}
            onContextMenu={(e) => handleRightClick(e, r, c)}
            style={{
                backgroundColor: cell.isRevealed
                ? cell.isMine
                    ? 'red'
                    : '#ddd'
                : cell.isFlagged
                ? '#199'
                : '#999',
                color:
                cell.isRevealed && cell.adjacentMines > 0
                    ? getColorForAdjacentMines(cell.adjacentMines)
                    : 'black', // only show color if revealed
                fontWeight: 'bold',
                border: cell.isRevealed
                ? cell.isMine
                    ? '3px solid #333'
                    : '1px solid #333'
                : cell.isFlagged
                ? '1px dotted #333'
                : '1px solid #333',
                cursor: gameOver || win ? 'default' : 'pointer',
            }}
            disabled={gameOver || win}
            className="tile"
            >
            {cell.isRevealed
                ? cell.isMine
                ? '💣' // Display 'B' if the cell is a revealed mine
                : cell.adjacentMines || '' // Display number of adjacent mines, or empty if zero
                : cell.isFlagged
                ? '🚩' // Display 'F' if the cell is flagged and not revealed
                : ''} {/* Display nothing if not revealed and not flagged */}
            </motion.button>

          )})
        )}
      </div>
      {(gameOver || win) && (
        <button className='btn btn-light' onClick={() => resetGame(win?level+1 > 5?5:level+1:level)} style={{ marginTop: 20 }}>
          {win?"Next Level":"Restart Level"}
        </button>
      )}
    </div>
    </div>
  );
};

export default MineSweeperGame;
