import React, { useState, useEffect, useRef } from 'react';
import "./Bio.css";
import { useSpring, animated } from '@react-spring/web';
import { motion } from 'framer-motion';
import photo from "../images/Kostas_ProfilePic.jpg";
import SkillsComponent from '../components/SkillsComponent';
import MatrixCode from '../components/MatrixCode';
import paper_edges from '../images/hard_paper_texture.png';
import { Toast } from 'bootstrap';
import { isEmptyObject } from 'jquery';
import paperUnfold from '../images/paper_unfold.png';
import paperUnfold2 from '../images/paper_unfold2.png';
import paper from '../images/paper.png';
import paper2 from '../images/paper2.png';
import paperBall from '../images/paper_ball_good.png';
import paperPanel from '../images/clean_paper_panel.png';


function Bio() {
  const paperTextRef = useRef(null); // Create a ref to access the element
  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  );

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);

    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);

  useEffect(() => {
    //const element = document.getElementById('paperText');
    const element = paperTextRef.current;
    
    // Ensure the element is found
    if (element) {
        element.style.backgroundImage =  `url(${paperPanel})`;
        element.style.backgroundSize = "cover";
        const firstAnimationTimeout = setTimeout(() => {
            // Log the current background image
            //console.log("1 - bgimage: ", window.getComputedStyle(element).backgroundImage); // Log computed style
            element.style.backgroundImage =  `url(${paperPanel})`;
            element.style.padding = "10%";
            element.style.backgroundSize = "cover";
            // element.style.backgroundSize = "cover";
            const secondShakeTimeout = setTimeout(() => {
                // Trigger the second shake after the first background is set
                //console.log("2 - bgimage: ", window.getComputedStyle(element).backgroundImage); // Log computed style
                // element.style.backgroundImage =  `url(${paperUnfold2})`;
                
                // After the second shake, change to the final background image
                const finalBackgroundTimeout = setTimeout(() => {
                    // element.style.backgroundImage =  `url(${paperPanel})`;
                    // element.style.padding = "10%";
                    // element.style.backgroundSize = "cover";
                },250); // Wait for the shake to finish

                return () => clearTimeout(finalBackgroundTimeout); // Clean up the timeout
            }, 250); // Wait for the first animation to finish

            return () => clearTimeout(secondShakeTimeout); // Clean up the timeout
        }, 750); // Initial delay before starting the first background
        
        return () => clearTimeout(firstAnimationTimeout); // Clean up the timeout
    } else {
        console.error("Element not found");
    }
}, []); // Empty dependency array to run once on mount
 
  return (
    <div> 
   
      {/* <motion.img className="paper-overlay" src={paper_edges} alt=""  initial={{opacity:0 , transition: { duration: 0.1}}} animate={{opacity:0.5, transition: { duration: 0.5, delay:0 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}/> */}
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`mt-3 container-fluid my-body ${isVisible ? 'visible' : ''}`}>
        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
          <animated.div style={propsSlideRightOp}>
            <div className='container-fluid top-bar'>
              <div className='row align-items-center'>
                <div className='h1 fw-light text-decoration paper-title w-auto m-auto'>Biography </div>
              </div>
            </div>
          </animated.div>
        </motion.div>

        <br/>

        <motion.div key="body" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
        
          <div className="container biography-container fw text-decoration scrollable">
            <div className="row">
              <div className="col-md-4">
                <motion.div className="" initial={{opacity:0,x:"-0vw",rotate:40, rotateX:"-91deg",rotateZ:"-5deg", scale:2, transition: { duration: 0.1}}} animate={{opacity:1,x:"0",rotate:0, rotateX:0,rotateZ:"0deg", scale:1, transition: { duration: 1, delay:0.5 , ease: "backInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backInOut" }}}>
                  <div className='custom-hover2 profile-picture'>
                    <img src={photo} alt="Profile" className="my-pic" />
                  </div> 
                </motion.div>
              </div>

              <div className="col-md-8 custom-hover">

                <motion.div className="text-decoration h5 fw-light paper-text" id='paperText' ref={paperTextRef} initial={{x:"50vw",y:"0",rotate:"60deg", scale : 0 , transition: { duration: 0.1}}} animate={{x:0,y:0, rotate:"0deg", scale : 1 , transition: { duration: 1, delay:0.4 , ease: "backOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backOut" }}} style={{height : "auto"}}>
                  <motion.div className="personal-info text-decoration h5 fw-light" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.2, delay:0 , ease: "ease"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}}>
                      With a foundation in full-stack development and a diverse portfolio across freelance and corporate environments, I specialize in crafting tailored digital solutions driven by a passion for problem-solving and a focus on clean, maintainable code.<br/><br/>
                      Driven by a passion for problem-solving and a focus on clean, maintainable code, I bring an adaptive skill set and a commitment to continuous learning in an ever-evolving tech landscape.
                      Currently, I contribute to digital business applications at Titan Cement Company Greece, where I leverage Microsoft’s business platforms to streamline and enhance operational workflows. <br/><br/>

                      
                  </motion.div>
                </motion.div>

              </div>  
              <hr/>
              <motion.div initial={{opacity:0,y:"30vh",rotateZ:"0deg", scale:1, transition: { duration: 0.1}}} animate={{opacity:1, y:0,rotateZ:"0deg", scale:1, transition: { duration:0.7, delay:0.7 , ease: "backOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backOut" }}} className="row skill-container">
                    <br/>
                    <SkillsComponent className=''/>
              </motion.div>
              <br/><br/>
              
            </div>
          </div>
        </motion.div>
      <br/><br/><br/><br/><br/><br/>
      </motion.div>
      
    </div>
  );
}

export default Bio;