import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Web.css';
import { Stars, Cloud, Environment, Clouds } from '@react-three/drei';

function Web() {
    const [detailsReact, setDetailsReact] = useState(false);
    const [detailsWordpress, setDetailsWordpress] = useState(false);

    const propsSlideRightOp = useSpring({
        from: { opacity: 0, y: '-100%' },
        to: { opacity: 1, y: '0%' },
        delay: 500,
    });

    const handleDetailsReact = () => {
        setDetailsWordpress(false);
        setDetailsReact(!detailsReact);
    };

    const handleDetailsWordpress = () => {
        setDetailsReact(false);
        setDetailsWordpress(!detailsWordpress);
    };

    return (
        <div>
            <motion.div
                exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } }}
                className="container"
            >
                <motion.div
                    exit={{ opacity: 0, transition: { duration: 0.2, ease: 'easeInOut' } }}
                >
                    <animated.div style={propsSlideRightOp}>
                        <div className="row align-items-center glitchy-text">
                            <div className="h1 fw-light glitchy-text"  data-text="Web Development &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                                Web Development &nbsp;
                                <FontAwesomeIcon icon={faTerminal} style={{ color: '#ffffff' }} />
                            </div>
                        </div>
                    </animated.div>
                </motion.div>

                <br />

                <motion.div
                    key="portfolio-body"
                    initial={{ opacity: 0, transition: { duration: 0.1 } }}
                    animate={{ opacity: 100, y: '0%', scale: 1, transition: { duration: 0.5, delay: 0.2, ease: 'easeInOut' } }}
                    exit={{ opacity: 0, scale: 1, x:"-50vw" , transition: { duration: 0.4, ease: 'easeInOut' } }}
                    className="dots-div mx-auto"
                >
                    <div className="align-items-center justify-content-center">
                        <motion.div
                            className="my-weird-body"
                            initial={{ opacity: 0, transition: { duration: 0.1 } }}
                            animate={{ opacity: 100, transition: { duration: 0.7, delay: 0.1, ease: 'easeInOut' } }}
                            exit={{ opacity: 0, transition: { duration: 0.2, ease: 'easeInOut' } }}
                        >
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-4 glitchy-div">
                                    <div className="fw-light">
                                        <h3>Website Portfolio</h3>
                                        <div className="row justify-content-center">
                                            <button
                                                className={`btn btn-outline-light portfolio-btn ${detailsReact ? 'btn-secondary' : ''}`}
                                                onClick={handleDetailsReact}
                                            >
                                                <div className="typewriter-text" data-text="React.js">
                                                    React.js
                                                </div>
                                            </button>
                                        </div>
                                        <div className="row justify-content-center">
                                            <button
                                                className={`btn btn-outline-light portfolio-btn ${detailsWordpress ? 'btn-secondary' : ''}`}
                                                onClick={handleDetailsWordpress}
                                            >
                                                <div className="typewriter-text" data-text="WordPress">
                                                    WordPress
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col mx-auto">
                                    <h3>&nbsp;</h3>
                                    <div className="col-lg-11 col-sm-11 col-xs-10">
                                        <div className={`my-box2 ${detailsReact ? 'come' : ''}`}>
                                            <div>
                                                <div className="h5 fw-light entry">
                                                    <a href="#" className="h5 fw-light entry">
                                                        kajoo.gr
                                                    </a>
                                                </div>
                                                <div className="h5 fw-light entry">
                                                    <a href="https://www.ek-creative.gr">ek-creative.gr</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`my-box2 mx-auto ${detailsWordpress ? 'come' : ''}`}>
                                            <div className="h5 fw-light entry">
                                                <a
                                                    href="https://www.georgopoulostransfers.gr"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="h5 fw-light entry"
                                                >
                                                    georgopoulostransfers.gr
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                <br />
                                <br />
                                </div>
                            </div>
                        </motion.div>
                        <br />
                        <br />
                        <br />

                        <div className="row align-items-center">
                            <div className="h1 fw-light">
                                Want your own? Feel free to{' '}
                                <Link
                                    to="/contact"
                                    style={{ color: 'inherit', textDecoration: 'green wavy underline' }}
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.2 }}
                                        className="h2 fw-light text-decoration hover-zoom"
                                        style={{ '--fa-animation-delay': '0.1s' }}
                                    >
                                        contact me!
                                    </motion.div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>

            <br />
            <br />
            <br />
        </div>
    );
}

export default Web;
