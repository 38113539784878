import React from 'react';
import "./Games.css";
import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect, useRef } from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import HoopGame from '../components/HoopGameComponent/HoopGame';
import MineSweeperGame from '../components/MinesweeperComponent/MineSweeperGame';
//import AirHockeyGame from '../components/AirHockeyGameComponent/AirHockeyGame';


function Games(){
  const [hoopGame, setHoopGame] = useState(false);
  const hoopGameRef = useRef(false);
  // const [airHockeyGame, setAirHockeyGame] = useState(false);
  // const airHockeyGameRef = useRef(false);
  const [mineSweeperGame, setMineSweeperGame] = useState(false);
  const MineSweeperGameRef = useRef(false);

  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  )

  const [isVisible, setIsVisible] = useState(false);

  // const handleAirHockeyGame = ()=>{
  //   if(airHockeyGameRef.current){
  //     airHockeyGameRef.current = false;
  //     setAirHockeyGame(false);

  //     hoopGameRef.current = false;
  //     setHoopGame(false);
  //   }
  //   else{
  //     hoopGameRef.current = false;
  //     setHoopGame(false);

  //     airHockeyGameRef.current = true;
  //     setAirHockeyGame(true);
  //   }
  // }

  const handleMineSweeperGame = ()=>{
    if(MineSweeperGameRef.current){
      MineSweeperGameRef.current = false;
      setMineSweeperGame(false);

      hoopGameRef.current = false;
      setHoopGame(false);
    }
    else{
      hoopGameRef.current = false;
      setHoopGame(false);

      MineSweeperGameRef.current = true;
      setMineSweeperGame(true);
    }
  }

  const handleHoopGame = ()=>{
    if(hoopGameRef.current){
      MineSweeperGameRef.current = false;
      setMineSweeperGame(false);

      hoopGameRef.current = false;
      setHoopGame(false);
    }
    else{
      
      MineSweeperGameRef.current = false;
      setMineSweeperGame(false);
      
      hoopGameRef.current = true;
      setHoopGame(true);

    }
  }

  const resetComponent = () => {
    //console.log("here");
    toggleMineSweeperGame();
  }

  const toggleMineSweeperGame = () => {
    // Set to false immediately
    MineSweeperGameRef.current = false;
    setMineSweeperGame(false);
    // Set to true after 1 second (1000 ms)
    setTimeout(() => {
      MineSweeperGameRef.current = true;
      setMineSweeperGame(true);
      //console.log("MineSweeperGameRef.current is set to true"); // Confirming the change
    }, 1000);
  }

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);

    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);
  
    return (
    <div> 
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`container-fluid my-body ${isVisible ? 'visible' : ''}`}>

        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
            <animated.div style={propsSlideRightOp}>
              <div className='container-fluid top-bar'>
          
                <div className='row align-items-center '>
                  <div className='h1 fw-light text-decoration'>Games</div>
                  {/* <div className='h5 fw-light'>to pass the time</div> */}
                </div>
              </div>
            </animated.div>
        </motion.div>

        <br/>
        
        <motion.div key="body" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.5, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div"> 
        <div className="container justify-content-center">
          <div className='row'>
            <div className='btn btn-lg btn-outline-warning col' onClick={()=>handleHoopGame()}>Hoops</div>
            {/* <div className='btn btn-lg btn-outline-success col-6' onClick={()=>handleAirHockeyGame()}>AIR-HOCKEY</div> */}
            <div className='btn btn-lg btn-outline-warning col' onClick={()=>handleMineSweeperGame()}>MineSweeper</div>
          </div>

          <br></br><br></br>
          <hr/>

          <AnimatePresence>
          {hoopGameRef.current?
            <motion.div key="body1" initial={{opacity:0, scale:'0%', transition: { duration: 0.1}}} animate={{opacity:100, scale:'100%', transition: { duration: 0.5, delay:0.8 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="game">
              <HoopGame/>
            </motion.div>
          :<div key="body3"/>}
          {MineSweeperGameRef.current?
              <MineSweeperGame />

          :<div key="body4"/>}
          </AnimatePresence>
        </div>
        </motion.div>


        </motion.div>

      


    </div>

    );
    
  }
export default Games;