// CloudBackground.js
import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Cloud } from '@react-three/drei';

const DispersingCloud = () => {
  const cloudRef = useRef();
  const [scale, setScale] = useState(2); // Initial scale for zoom effect

  // Rotate and animate the scale of the clouds
  useFrame(() => {
    if (cloudRef.current) {
      cloudRef.current.rotation.y += 0.0005; // Slow rotation for subtle effect
      cloudRef.current.scale.set(scale, scale, scale);
    }
  });

  // Toggle scale for dispersing/zooming effect


  return (
    <group ref={cloudRef}>
      {/* The cloud shape remains consistent because it’s part of a stable group */}
      <Cloud opacity={0.05} speed={0.3} width={10} depth={1.5} segments={5} position={[0, 0, -2]} />
    </group>
  );
};

const CloudBackground = () => {
  const canvasRef = useRef();

  return (
    <Canvas ref={canvasRef} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", pointerEvents: "none" }}>
      <ambientLight intensity={0.5} />
      <DispersingCloud />
    </Canvas>
  );
};

export default CloudBackground;
